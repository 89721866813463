<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'payments'}">Payments</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Create Payment</a></li>
    </ol>
    <panel noButton="true" title="Create Payment" variant="panel-default">

      <div v-html="$error.handle(error)" />

      <form @submit.prevent="submitForm">
        <div class="row">
          <div
              :class="{ 'has-feedback has-error': errors.has('transId')}"
              class="form-group col-md-6"
          >
            <label for="transId">Transaction Reference:</label>
            <input
                id="transId"
                v-model="form.TransID"
                v-validate="'required'"
                class="form-control"
                name="transId"
                type="text"
                required
            >
            <small
                v-if="errors.has('transId')"
                class="help-block"
            >
              {{ errors.first('transId') }}
            </small>
          </div>

          <div class="col-md-6">
            <div
                :class="{ 'has-feedback has-error': errors.has('transAmount')}"
                class="form-group"
            >
              <label for="transAmount">Amount:</label>
              <vue-numeric
                  v-model="form.TransAmount"
                  v-validate="'required'"
                  class="form-control"
                  name="transAmount"
                  precision="2"
              />
              <small
                  v-if="errors.has('transAmount')"
                  class="help-block"
              >
                {{ errors.first('transAmount') }}
              </small>
            </div>
          </div>

          <div class="col-md-6">
            <div
                :class="{ 'has-feedback has-error': errors.has('billRefNumber')}"
                class="form-group"
            >
              <label for="billRefNumber">Account:</label>
              <input
                  id="billRefNumber"
                  v-model="form.BillRefNumber"
                  v-validate="'required'"
                  name="billRefNumber"
                  class="form-control"
                  type="text"
                  required
              >
              <small
                  v-if="errors.has('billRefNumber')"
                  class="help-block"
              >
                {{ errors.first('billRefNumber') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div
                :class="{ 'has-feedback has-error': errors.has('phone')}"
                class="form-group"
            >
              <label for="msisdn">Phone:</label>
              <input
                  v-model="form.MSISDN"
                  name="phone"
                  class="form-control"
                  type="text"
              >
              <small
                  v-if="errors.has('phone')"
                  class="help-block"
              >
                {{ errors.first('phone') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div
                :class="{ 'has-feedback has-error': errors.has('name')}"
                class="form-group"
            >
              <label for="name">Payer Name:</label>
              <input
                  v-model="form.FirstName"
                  v-validate="'required'"
                  name="name"
                  class="form-control"
                  type="text"
                  required
              >
              <small
                  v-if="errors.has('name')"
                  class="help-block"
              >
                {{ errors.first('name') }}
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <button
                  v-if="confirming"
                  type="button"
                  class="btn btn-warning btn-loading"
              >
                <i
                    class="fa fa-spin fa-spinner"
                /> Verifying payment
              </button>
              <button
                  v-else
                  type="submit"
                  class="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </panel>
  </div>
</template>

<script>
import moment from 'moment/src/moment';

export default {
  data() {
    return {
      form: {
        TransactionType: 'Pay Bill',
        TransID: '',
        TransTime: moment().format('YYYYMMDDHHmmss'),
        TransAmount: 0,
        BusinessShortCode: process.env.VUE_APP_MPESA_PAYBILL,
        BillRefNumber: this.$route.params.account,
        MSISDN: '',
        FirstName: '',
        MiddleName: '',
        LastName: ''
      },
      error: '',
      confirming: false
    }
  },
  methods: {
    submitForm(scope) {
      return this.$validator.validateAll(scope).then(result => {
        if (result) {
          this.setConfirmation();
        }
      });
    },
    setConfirmation() {
      const self = this;
      self.confirming = true;
      this.$axios.post('/api/v1/payment/validation', this.form).then((resp) => {
        self.confirming = false;
        if (resp.data.ResultCode === 0)
          self.$toastr.s("Payment confirmation successful");
      }).catch(err => {
        this.confirming = false;
        this.error = err.response;
      });
    }
  }
}
</script>

<style lang="scss">
</style>